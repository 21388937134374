import React from "react";

const Impressum = () => {
  return (
    <div>
      <div className="pl-10 bg-violet-500 pr-10 pb-10 pt-10 text-violet-200">
        <h1 className="text-6xl">Impressum</h1>
        <h2 className="text-4xl mt-6">Angaben gemäß § 5 TMG</h2>
        <p className="m-0">Fayesxh</p>
        <p className="m-0">c/o INSIGHT GG GMBH</p>
        <p className="m-0">Müllerstraße 12A</p>
        <p className="m-0">86153 Augsburg</p>
        <p className="m-0">Vertreten durch:</p>
        <p>
          Peter Alexander Oettel (Geschäftsführer)
        </p>

        <h2 className=" text-4xl mt-4">Kontakt</h2>
        <p><a href="mailto:fayesxh@ins.gg" className="no-underline text-violet-200">E-Mail: fayesxh@ins.gg</a></p>
        <h2 className=" text-4xl mt-4">Umsatzsteuer-ID</h2>
        <p>DE342808965</p>
        <h2 className="text-4xl mt-4">Streitbeilegung</h2>
        <h2 className="text-4xl">
          EU-Plattform zur Online-Streitbeilegung
        </h2>
        <p>
          Informationen zur Online-Streitbeilegung nach Artikel 14 Abs. 1
          ODR-VO. Die EU-Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS-Plattform) verbraucherrechtlicher
          Streitigkeiten bereit, die aus Online-Kaufverträgen und
          Online-Dienstleistungsverträgen resultieren. Diese Plattform erreichen
          Sie im Internet unter: http://ec.europa.eu/consumers/odr/.
        </p>

        <h2 className=" text-4xl mt-4">
          Informationen zu § 36 VSBG
        </h2>
        <p>
          Zur Einleitung eines außergerichtlichen Streitbeilegungsverfahrens
          über die in § 47a TKG genannten Fälle können Sie einen entsprechenden
          Antrag an die Verbraucherschlichtungsstelle Telekommunikation der
          Bundesnetzagentur (Postfach 80 01, 53105 Bonn;
          www.bundesnetzagentur.de) richten.
        </p>
      </div>
    </div>
  );
};

export default Impressum;
