import React from "react";
import "../styles/expandingImage.css";
import {HeroSection} from "../comp/HeroSection";
import {AbouteMe} from "../comp/AbouteMe";
import {Container} from "react-bootstrap";
import {Footer} from "../comp/Footer";

const Home = () => {
  return (
    <div className=" text-white">
        <div id="hero" className="relative mb-10">
            <HeroSection />
        </div>

    </div>
  );
};

export default Home;
