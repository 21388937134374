import React from 'react'
import {Col, Container, Row} from "react-bootstrap";
import {useNavigate} from "react-router-dom";

export interface IHeroSection {
  imgUrl?: string,
  title: string,
  desc: string,
  linkTo?: string,
  countDesc?: string,
  countNumber?: number,
}

export const HeroSection = () => {
  const nav = useNavigate();
  const heroSectionStaticPropsOne: IHeroSection[] = [
    {
      title: "Instagram",
      desc:"Instagram",
      linkTo: "https://www.instagram.com/faye.sxh/",
    },
    {
      title: "Twitch",
      desc:"Instagram",
      linkTo: "https://www.twitch.tv/fayesxh",
    },
    {
      title: "YouTube",
      desc:"Instagram",
      linkTo: "https://www.youtube.com/c/fayesxh",
    }
  ];

  const heroSectionStaticPropsTwo: IHeroSection[] = [
    {
      title: "TikTok",
      desc:"Instagram",
      linkTo: "https://www.tiktok.com/@fayesxh",
    },
    {
      title: "Discord",
      desc: "Twitter",
      linkTo: "https://discord.fayesxh.de",
    },
    {
      title: "Snapchat",
      desc: "Twitter",
      linkTo: "https://www.snapchat.com/add/realfayesxh",
    }
  ];

  return (
      <>
        <div className="m-0 w-full">
          <Row className="w-full m-0 border-l-4 border-r-4 border-t-8 border-black border-solid">
            {heroSectionStaticPropsOne.map((element, index) => {
              return(
              <Col className="w-full p-0" key={index} md={4}>
                <div className="w-full relative bg-yellow-50 border-8 border-black border-solid text-center">
                  <img src={"img/Faye" + index + ".jpg"} className="w-full block h-auto border-b-black"/>
                  <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full opacity-0001 transition bg-main/[.5] hover:opacity-100">
                    <div onClick={(e) => {window.open(element.linkTo, '_blank', 'noreferrer')}}
                         className="white flex h-full justify-around items-center text-center">
                      <div>
                        <h1>{element.title}</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>);
            })}
          </Row>

          <Row className="w-full m-0 border-l-4 border-r-4 border-b-8 border-black border-solid">
            {heroSectionStaticPropsTwo.map((element, index) => {
              return(
                  <Col className="w-full p-0" key={index} md={4}>
                    <div className="w-full relative bg-yellow-50 border-8 border-black border-solid text-center">
                      <img src={"img/Faye" + ( index+3) + ".jpg"} className="w-full block h-auto border-b-black"/>
                      <div className="absolute top-0 bottom-0 left-0 right-0 h-full w-full opacity-0001 transition bg-main/[.5] hover:opacity-100">
                        <div onClick={(e) => {window.open(element.linkTo, '_blank', 'noreferrer')}}
                             className="white flex h-full justify-around items-center text-center">
                          <div>
                            <h1>{element.title}</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>);
            })}
          </Row>
        </div>
      </>
  )
}

