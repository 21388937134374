import React from "react";
import {AppRouter} from "./AppRouter";
import 'bootstrap/dist/css/bootstrap.min.css';
function BaseApp() {
  return (
    <AppRouter />
  );
}

export default BaseApp;
