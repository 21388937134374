import React from 'react'
import NavBar from "./comp/Header";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./views/Home";
import Linktree from "./views/Linktree";
import Impressum from "./views/Impressum";

export const AppRouter = () => {
  return (
      <BrowserRouter>
          <div className="site bg-violet-500 h-screen">
              <title>FAYESXH</title>
              <NavBar />

              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/links" element={<Linktree />} />
                  <Route path="/impressum" element={<Impressum />} />
              </Routes>

          </div>
      </BrowserRouter>
  )
}

