import React from "react";
import { Link } from "react-router-dom";
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
const Header = () => {
    function classNames(...classes: any[]) {
        return classes.filter(Boolean).join(' ')
    }
    const navigation = [
        { name: 'Kontakt', href: 'impressum', current: false },
        { name: 'Links', href: 'links', current: false },
    ]


  return (
      <>

          <Disclosure as="nav" className="text-white no-underline">
              {({ open }) => (
                  <>
                      <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                          <div className="relative flex flex-row h-16 items-center justify-between">
                              <div className="absolute w-full inset-y-0 left-0 flex items-center sm:hidden">
                                  {/* Mobile menu button*/}
                                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                                      <span className="sr-only">Open main menu</span>
                                      {open ? (
                                          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                      ) : (
                                          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                      )}
                                  </Disclosure.Button>
                              </div>
                              <div className="flex flex-1 items-center w-full justify-around sm:items-stretch sm:justify-start">
                                  <div className="flex flex-shrink-0 items-center">
                                      <Link to="/" className="no-underline text-white">
                                        <h1 className="m-0">FAYESXH</h1>
                                      </Link>
                                  </div>
                                  <div className="hidden sm:ml-6 sm:block float-right">
                                      <div className="flex h-full items-center space-x-4">
                                          {navigation.map((item) => (
                                              <Link to={"/" + item.href} className="no-underline text-white">
                                                  <h1 className="text-xl m-0">{item.name}</h1>
                                              </Link>
                                          ))}
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>

                      <Disclosure.Panel className="sm:hidden">
                          <div className="space-y-1 px-2 pb-3 pt-2">
                              {navigation.map((item) => (
                                  <Disclosure.Button
                                      key={item.name}
                                      as="a"
                                      href={item.href}
                                      className={classNames(
                                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                          'block rounded-md px-3 py-2 text-base font-medium'
                                      )}
                                      aria-current={item.current ? 'page' : undefined}
                                  >
                                      {item.name}
                                  </Disclosure.Button>
                              ))}
                          </div>
                      </Disclosure.Panel>
                  </>
              )}
          </Disclosure>
          </>
  );
};

export default Header;
