import React from "react";
import "../styles/Linktree.css";

const Linktree = () => {
  interface urlData {
    site: string;
    color: string;
    img: string;
    url: string;
  }

  const data: urlData[] = [
    {
      site: "Twitch",
      color: "bg-violet-600",
      img: "",
      url: "https://www.twitch.tv/fayesxh",
    },
    {
      site: "Instagram",
      color: "instagram",
      img: "",
      url: "https://www.instagram.com/faye.sxh/",
    },
    {
      site: "Amazon wishlist",
      color: "bg-orange-500",
      img: "",
      url: "https://www.amazon.de/hz/wishlist/ls/1QXPGFH7MG770?ref_=wl_share",
    },
    {
      site: "YouTube",
      color: "bg-red-700",
      img: "",
      url: "https://www.youtube.com/c/fayesxh",
    },
    {
      site: "TikTok",
      color: "bg-black text-white",
      img: "",
      url: "https://www.tiktok.com/@fayesxh",
    },
    {
      site: "Discord",
      color: "bg-social-discord",
      img: "",
      url: "https://discord.fayesxh.de",
    },
    {
      site: "Snapchat",
      color: "bg-social-snapchat",
      img: "",
      url: "https://www.snapchat.com/add/realfayesxh",
    },
    {
      site: "Impressum",
      color: "bg-white",
      img: "",
      url: "/impressum",
    },
  ];

  return (
      <div className="">
        <div className="background-image bg-center items-center">
          <div className="pt-10 pb-10 z-30 flex flex-col">
            <div className="sticky flex justify-around">
              <img
                className="avatar-image pl-10 pr-10 h-2/4 w-2/4"
                alt="Fayesxh avatar"
                src="/avatar.jpg"
              />
            </div>
            <ul className="text-center p-0">
              {data.map((e: urlData, index ) => (
                <li className="mt-10" key={index}>
                  <button
                    onClick={(ea) => {
                      window.location.href = e.url;
                    }}
                    className={
                      e.color +
                      " bg-opacity-70 pt-4 pb-4 w-5/6 text-4xl text-center rounded-md"
                    }
                  >
                    {e.site}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
  );
};

export default Linktree;
